import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";

import INFO from "../data/user";

import "./styles/homepage.css";

const Homepage = () => {
	const [logoSize] = useState(80);

	const logoStyle = {
		display: "flex",
		position: "relative",
		top: "auto",
		zIndex: 999,
		border: "none",
		borderRadius: "none",
		boxShadow: "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={INFO.homepage.description} />
			</Helmet>

			<div className="page-content">
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									Simon Drake Development
								</div>

								<div className="subtitle homepage-subtitle">
									Converting your ideas into websites. Contact us via <a href = "mailto: simon@simondrakedevelopment.com">Email</a> if you need help building your next idea.
								</div>
							</div>

						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
